// extracted by mini-css-extract-plugin
export var container = "TabContent-module--container--4091f";
export var item = "TabContent-module--item--f8eef";
export var listContainer = "TabContent-module--list-container--9f898";
export var listItemContainer = "TabContent-module--list-item-container--b1ff5";
export var svgAds = "TabContent-module--svg-ads--87056";
export var svgBudget = "TabContent-module--svg-budget--717af";
export var svgContainer = "TabContent-module--svg-container--16a67";
export var svgLeads = "TabContent-module--svg-leads--da8ed";
export var textContainer = "TabContent-module--text-container--193cc";
export var wrapper = "TabContent-module--wrapper--ae17f";