import React from 'react'
import parse from 'html-react-parser'
import SpliderButtons from '@components/global/buttons/SpliderButtons'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'

import * as cx from './Strategy.module.scss'

import SvgCheckedCircle from '@svg/checkedCircle.inline'

const Strategy = ({
  bgImage,
  devices,
  heading,
  headingClassName,
  subheading,
  subheadingClassName,
  list,
  listHeadingClassName,
  listSubheadingClassName,
  listRateClassName,
}) => {
  return (
    <section
      id="strategy"
      className={clsx(cx.wrapper)}
      style={{
        backgroundImage: `url(${bgImage ? bgImage : 'none'})`,
      }}
    >
      <div className={clsx(cx.heading)}>
        <h3 className={subheadingClassName}>{subheading}</h3>
        <h2 className={headingClassName}>{heading}</h2>
      </div>

      <div className={clsx(cx.container)}>
        <Splide
          hasTrack={false}
          tag="div"
          options={{
            type: 'slide',
            arrows: 2,
            drag: false,
            arrowPath: false,
            autoplay: true,
            rewind: false,
            interval: 3500,
            pagination: false,
            perPage: 2,
            perMove: 2,
            autoWidth: false,
            focus: 'left',
            autoHeight: false,
            lazyLoad: 'nearby',
            omitEnd: true,
            breakpoints: {
              1023: {
                perMove: 1,
                perPage: 1,
                autoWidth: true,
                focus: 'center',
              },
              767: {
                drag: true,
                arrows: 0,
                autoWidth: false,
                focus: 'left',
              },
            },
          }}
        >
          <SplideTrack>
            {list.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <div className={clsx(cx.content)}>
                    <div className={clsx(cx.device)}>
                      <ElementImage
                        lazy={false}
                        src={devices[index]}
                        alt={'Device Image'}
                      />
                    </div>
                    <div className={clsx(cx.details)}>
                      <h2 className={listHeadingClassName}>{item.heading}</h2>
                      <h3 className={listSubheadingClassName}>
                        {item.subheading}
                      </h3>
                      <hr />
                      <div className={clsx(cx.increase)}>
                        <SvgCheckedCircle />
                        <p className={listRateClassName}>{item.rate}</p>
                      </div>
                      <button className="primary-button large">
                        Request More Information
                      </button>
                    </div>
                  </div>
                </SplideSlide>
              )
            })}
          </SplideTrack>
          <SpliderButtons />
        </Splide>
      </div>
    </section>
  )
}

export default Strategy
