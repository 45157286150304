export const pageData = {
  title: 'Real Estate PPC | Search Engine Marketing',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Internet Marketing',
      url: '/real-estate-internet-marketing/',
    },
    {
      text: 'Real Estate PPC for Agents',
      url: '/real-estate-internet-marketing/real-estate-ppc/',
    },
  ],
  uri: '/real-estate-internet-marketing/real-estate-ppc/',
  buttonsHeight: { desktop: 1880, tablet: 2200, phone: 2880 },
  lcf: {
    titleClassName: 'heading-2',
  },
}

export const bannerData = {
  layout: { container: 'product-intro-bg' },
  heading: 'Search Engine Marketing',
  headingClassName: 'heading-1',
  subheading: 'Real Estate PPC',
  subheadingClassName: 'subtitle-7',
  desc:
    'Boost your business with real estate PPC search engine marketing.<br/> Our team understands the nuances of real estate and will craft a PPC strategy designed to meet your needs.',
  descClassName: 'subtitle-5',
}

export const tabsData = {
  activeTab: 2,
  tabsClassName: 'small-heading',
  heading: 'Benefits of<br/> Search Engine Marketing',
  headingClassName: 'heading-2',
  subheading: [
    'Real estate search engine marketing is a great strategy for any realtor looking to draw in new buyers. The key metrics are impressions and clicks, with the more successful ads having higher numbers of both.',
    "With lower bids than traditional advertising methods, there's potential here to get your name out without spending too much money!",
  ],
  subheadingMobile: [
    'Real estate search engine marketing is a great strategy for any realtor looking to draw in new buyers.',
    'The key metrics are impressions and clicks, with the more successful ads having higher numbers of both.',
    "With lower bids than traditional advertising methods, there's potential here to get your name out without spending too much money!",
  ],
  subheadingClassName: 'subtitle-5',
  list: [
    {
      heading: 'Get More Traffic',
      desc: 'to your website with<br/> Google Ads',
      mobileDesc: 'to your website with<br/> Google Ads',
    },
    {
      heading: 'Save Time And Money',
      desc: 'by allocating a budget for<br/> lead generation',
      mobileDesc: 'by allocating a budget for lead<br/> generation',
    },
    {
      heading: 'Get Visibility and Leads',
      desc: 'on the map without tedious<br/> manual work',
      mobileDesc: 'on the map without tedious<br/> manual work',
    },
  ],
  listHeaderClassName: 'subtitle-6',
  listDescClassName: 'subtitle-5',
}

export const wwcdData = {
  heading: 'Paid Media Marketing',
  headingClassName: 'heading-3',
  subheading: 'What We Can Do',
  subheadingClassName: 'subtitle-7',
  subheading2: 'A cost-effective way to market and grow your business online.',
  subheading2ClassName: 'subtitle-5',
  desc:
    'A great ad that is well-timed and hyper-targeted can do wonders for your brand. Trust our paid media specialists and data analysts to make sure you get the most from your ad spend through high-performance campaigns designed to reach and entice your market to take action. We do more than just setting up ads, we can help you manage, monitor, and readjust your campaigns for optimum conversion.<p/>&nbsp;<p/>Go where your market is or risk losing them. Your business needs to be visible on high-intent searches on Google and social media. Turn those clicks into visits and those visits into leads.',
  descClassName: 'subtitle-5',
  bestFor:
    'Those looking to earn results in the form of quality leads, relevant site visits, landing page views, and social media vanity/ engagement metrics.',
  bestForClassName: 'subtitle-5',
}

export const bottomlineData = {
  heading: 'Why Choose PPC?',
  headingClassName: 'heading-3',
  subheading: 'The Bottomline',
  subheadingClassName: 'subtitle-7',
  desc:
    'Why spend money on ads that people don’t engage with? With PPC, you only pay when someone clicks, meaning all your marketing budget goes to red-hot leads.',
  descClassName: 'subtitle-5',
  list: [
    'Improve Online Visibility',
    'Boost Brand Awareness',
    'Generate Leads',
    'Drive Conversions',
    'Grow Online Sales',
    'Increase Landing Page Views',
    'Aquire Downloads and Installs',
    'Get Relevant Video Views',
  ],
  listClassName: 'subtitle-5',
}

export const packageData = {
  heading: 'PPC Lead Gen Package',
  headingClassName: 'heading-3',
  subheading: 'Package Inclusions',
  subheadingClassName: 'subtitle-1',
  list: [
    'Campaign Performance Review',
    'Ad Targeting Review',
    'Campaign Structure Audit',
    'Ad Extension Audit',
    'Ad Content Analysis',
    'Landing Page Audit',
    'Keyword Analysis and Research',
    'Search Ads Campaign Structure',
    'Ad Copy Creation',
    'Landing Page Content Creation',
    'Landing Page Development',
    'Performance Tracking Tool Setup',
    'Google Ads Campaign Setup',
    'Post-Launch QA and Adjustments',
    'Performance and Lead Report',
  ],
  listClassName: 'subtitle-5',
}

export const strategyData = {
  heading: 'Effective PPC Marketing Strategy',
  headingClassName: 'heading-3',
  subheading: 'Leverage The Power Of',
  subheadingClassName: 'subtitle-7',
  list: [
    {
      heading: 'T.D. Smith',
      subheading: 'tdsmith.com',
      rate: 'Increased Traffic by 88%',
    },
    {
      heading: 'Gavin Ernstone',
      subheading: 'thedhs.com',
      rate: 'Increased Click Through Rate by 60%',
    },
    {
      heading: 'John Dennis',
      subheading: 'domain.com',
      rate: 'Increased Click Through Rate by 75%',
    },
    {
      heading: 'Mary Weichert',
      subheading: 'domain.com',
      rate: 'Increased Traffic by 32%',
    },
  ],
  listHeadingClassName: 'heading-3',
  listSubheadingClassName: 'subtitle-7',
  listRateClassName: 'subtitle-5',
}

export const faqsData = [
  {
    question: 'How does search engine marketing help my business?',
    answer: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    ],
  },
  {
    question: 'How much control will I have with PPC?',
    answer: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    ],
  },
  {
    question: 'Can you guarantee me with a specific ranking on Google?',
    answer: [
      'There are hundreds of factors that affect organic search engine results placement, including geographic location and the age of the site. The good news is that PPC can offer a desirable ranking and search engine optimization will only improve this.',
    ],
  },
  {
    question: 'Is PPC advertising expensive?',
    answer: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    ],
  },
  {
    question: 'How long until PPC delivers the results I need?',
    answer: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    ],
  },
]

export const reviewData = {
  layout: 'B',
  title: 'Our Success Stories',
  subtitle:
    'Satisfaction comes standard when you work with Agent Image. But don’t take our word for it — we’ll let our elite clients do the talking!',
  subheader: 'Amazing Customer Experiences',
  subheaderClassName: 'subtitle-7',
}
