import React, { useState } from 'react'
import parse from 'html-react-parser'

import SvgCheckedCircle from '@svg/checkedCircle.inline'

import ElementImage from '@components/global/element/Image'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import clsx from 'clsx'

import * as cx from './Bottomline.module.scss'

const Bottomline = ({
  device,
  heading,
  headingClassName,
  subheading,
  subheadingClassName,
  desc,
  descClassName,
  list,
  listClassName,
  button_click,
}) => {
  return (
    <section id="strategy" className={cx.wrapper}>
      <div className={cx.container}>
        <div className={cx.imgContainer}>
          <ElementImage src={device} alt={'Banner Image'} />
        </div>
        <div className={cx.contentContainer}>
          <div className={cx.headerContainer}>
            <h3 className={subheadingClassName}>{subheading}</h3>
            <h1 className={headingClassName}>{heading}</h1>
            <p className={descClassName}>{desc}</p>
          </div>
          <div className={cx.listContainer}>
            {list.map((item, index) => {
              return (
                <div key={index} className={cx.listItemContainer}>
                  <SvgCheckedCircle className={cx.checkSvg} />
                  <p className={listClassName}>{item}</p>
                </div>
              )
            })}
          </div>
          <div className={cx.buttonContainer}>
            <button className="primary-button" onClick={button_click}>
              Request More Information
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Bottomline
