// extracted by mini-css-extract-plugin
export var bestForContainer = "WhatWeCanDo-module--best-for-container--35dd4";
export var bestForHeader = "WhatWeCanDo-module--best-for-header--bf995";
export var button = "WhatWeCanDo-module--button--59e9d";
export var container = "WhatWeCanDo-module--container--134c1";
export var contentContainer = "WhatWeCanDo-module--content-container--d7d83";
export var descContainer = "WhatWeCanDo-module--desc-container--1e811";
export var headerContainer = "WhatWeCanDo-module--header-container--80a9a";
export var imageContainer = "WhatWeCanDo-module--image-container--6821c";
export var svgLike = "WhatWeCanDo-module--svg-like--ca984";
export var textContainer = "WhatWeCanDo-module--text-container--7b0bc";
export var wrapper = "WhatWeCanDo-module--wrapper--24488";