import { useStaticQuery, graphql } from 'gatsby'

const usePPCMetadata = () => {
  const query = useStaticQuery(graphql`
    query PPCMetadata {
      bannerImg: file(relativePath: { eq: "ppc/banner-img.png" }) {
        id
        ...PPCImageOptimize
      }
      wwcdImg: file(relativePath: { eq: "ppc/wwcd-device.png" }) {
        id
        ...PPCImageOptimize
      }
      bottomlineBg: file(relativePath: { eq: "ppc/bottomline-bg.png" }) {
        id
        ...PPCImageOptimize
      }
      packageImg: file(relativePath: { eq: "ppc/package-device.png" }) {
        id
        ...PPCImageOptimize
      }
      strategy1: file(relativePath: { eq: "ppc/device-1.png" }) {
        id
        ...PPCImageOptimize
      }
      strategy2: file(relativePath: { eq: "ppc/device-2.png" }) {
        id
        ...PPCImageOptimize
      }
      strategy3: file(relativePath: { eq: "ppc/device-3.png" }) {
        id
        ...PPCImageOptimize
      }
      strategy4: file(relativePath: { eq: "ppc/device-4.png" }) {
        id
        ...PPCImageOptimize
      }
      faqsBg: file(relativePath: { eq: "ppc/faqs-bg.png" }) {
        id
        ...PPCImageOptimize
      }
      faqsBgT: file(relativePath: { eq: "ppc/faqs-bg-tablet.png" }) {
        id
        ...PPCImageOptimize
      }
      packageBg: file(relativePath: { eq: "ppc/package-bg.png" }) {
        id
        ...PPCImageOptimize
      }
      strategyBg: file(relativePath: { eq: "ppc/strategy-bg.png" }) {
        id
        ...PPCImageOptimize
      }
    }

    fragment PPCImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default usePPCMetadata
