import React, { useState } from 'react'
import parse from 'html-react-parser'

import SvgCheckedCircle from '@svg/checkedCircle.inline'
import SvgBudget from '@svg/gradient-icons/budget.inline'
import SvgGoogleAds from '@svg/gradient-icons/googleads.inline'
import SvgLeads from '@svg/gradient-icons/leads.inline'
import SvgBudgetAlt from '@svg/gradient-icons/budgetAlt.inline'
import SvgGoogleAdsAlt from '@svg/gradient-icons/googleadsAlt.inline'
import SvgLeadsAlt from '@svg/gradient-icons/leadsAlt.inline'

import ElementImage from '@components/global/element/Image'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import clsx from 'clsx'

import * as cx from './TabContent.module.scss'

const TabContent = ({
  heading,
  headingClassName,
  subheading,
  subheadingMobile,
  subheadingClassName,
  list,
  listHeaderClassName,
  listDescClassName,
}) => {
  const svgs = [
    <SvgGoogleAds key={1} />,
    <SvgBudget key={2} />,
    <SvgLeads key={3} />,
  ]

  const svgsMobile = [
    <SvgGoogleAdsAlt key={1} />,
    <SvgBudgetAlt key={2} />,
    <SvgLeadsAlt key={3} />,
  ]

  const isPhone = useWindowSize().width <= 767
  const isTablet = useWindowSize().width <= 1023

  return (
    <section id="strategy" className={cx.wrapper}>
      <div className={cx.container}>
        <h2 className={headingClassName}>{parse(heading)}</h2>
        <hr />
        <div className={cx.textContainer}>
          {isPhone
            ? subheadingMobile.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <p className={subheadingClassName}>{item}</p>
                    <p>&nbsp;</p>
                  </React.Fragment>
                )
              })
            : subheading.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <p className={subheadingClassName}>{item}</p>
                    <p>&nbsp;</p>
                  </React.Fragment>
                )
              })}
        </div>
        <hr />
        <div className={cx.listContainer}>
          {list.map((item, index) => {
            return (
              <div key={index} className={cx.listItemContainer}>
                <div className={clsx(cx.item, cx.svgContainer)}>
                  {isTablet ? svgsMobile[index] : svgs[index]}
                </div>
                <div className={cx.item}>
                  <h2 className={listHeaderClassName}>{item.heading}</h2>
                  <p className={listDescClassName}>
                    {isPhone ? parse(item.mobileDesc) : parse(item.desc)}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default TabContent
