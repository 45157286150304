import React, { useState } from 'react'
import parse from 'html-react-parser'

import SvgLike from '@svg/like.inline'

import ElementImage from '@components/global/element/Image'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import clsx from 'clsx'

import * as cx from './WhatWeCanDo.module.scss'

const WhatWeCanDo = ({
  device,
  heading,
  headingClassName,
  subheading,
  subheadingClassName,
  subheading2,
  subheading2ClassName,
  desc,
  descClassName,
  bestFor,
  bestForClassName,
  button_click,
}) => {
  return (
    <section id="strategy" className={cx.wrapper}>
      <div className={cx.container}>
        <div className={cx.headerContainer}>
          <h3 className={subheadingClassName}>{subheading}</h3>
          <h2 className={headingClassName}>{heading}</h2>
          <p className={subheading2ClassName}>{parse(subheading2)}</p>
        </div>
        <div className={cx.contentContainer}>
          <div className={cx.imageContainer}>
            <ElementImage src={device} alt={'Device Image'} />
          </div>
          <div className={cx.textContainer}>
            <div className={cx.descContainer}>
              <p className={descClassName}>{parse(desc)}</p>
            </div>
            <div className={cx.bestForContainer}>
              <div className={cx.bestForHeader}>
                <SvgLike className={cx.svgLike} />
                <p className={'subtitle-7'}>Best For</p>
              </div>
              <hr />
              <p className={bestForClassName}>{bestFor}</p>
            </div>
            <div className={cx.button}>
              <button className={'primary-button'} onClick={button_click}>
                Request More Information
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatWeCanDo
