import React, { useState } from 'react'
import parse from 'html-react-parser'

import SvgCheckedCircle from '@svg/checkedCircle.inline'

import ElementImage from '@components/global/element/Image'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import clsx from 'clsx'

import * as cx from './Package.module.scss'

const Package = ({
  bgImage,
  device,
  heading,
  headingClassName,
  subheading,
  subheadingClassName,
  list,
  listClassName,
  button_click,
}) => {
  return (
    <section
      id="strategy"
      className={cx.wrapper}
      style={{
        backgroundImage: `url(${bgImage ? bgImage : 'none'})`,
      }}
    >
      <div className={cx.imgContainer}>
        <ElementImage src={device} alt={'Banner Image'} className={cx.img} />
      </div>
      <div className={cx.container}>
        <div className={cx.headerContainer}>
          <h3 className={subheadingClassName}>{subheading}</h3>
          <h1 className={headingClassName}>{heading}</h1>
        </div>
        <div className={cx.listContainer}>
          {list.map((item, index) => {
            return (
              <div key={index} className={cx.listItemContainer}>
                <SvgCheckedCircle className={cx.checkSvg} />
                <p className={listClassName}>{item}</p>
              </div>
            )
          })}
        </div>
        <div className={cx.button}>
          <button className="primary-button" onClick={button_click}>
            Request More Information
          </button>
        </div>
      </div>
    </section>
  )
}

export default Package
