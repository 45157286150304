import React from 'react'
import LayoutInnerPage from '@src/layouts/InnerPage'

import Strategy from '@components/pages/real-estate-ppc/Strategy'
import TabContent from '@components/pages/real-estate-ppc/TabContent'
import WhatWeCanDo from '@components/pages/real-estate-ppc/WhatWeCanDo'
import Bottomline from '@components/pages/real-estate-ppc/Bottomline'
import Package from '@components/pages/real-estate-ppc/Package'

import Banner from '@components/global/banner/Banner'
import Seo from '@components/seo/Index'
import Tabs from '@components/global/tabs/DigitalMarketing'
import ReviewsServiceSetsUsApart from '@components/global/Reviews/ServiceSetsUsApart'
import Faqs from '@components/global/accordions/Faqs'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'

import {
  pageData as page,
  bannerData,
  tabsData,
  strategyData,
  wwcdData,
  bottomlineData,
  packageData,
  faqsData,
  reviewData,
} from '@src/data/PPC'
import usePPCMetadata from '@hooks/ppc-metadata'

import * as cx from './PPC.module.scss'

const PPC = () => {
  const {
    bannerImg,
    wwcdImg,
    bottomlineBg,
    packageImg,
    strategy1,
    strategy2,
    strategy3,
    strategy4,
    faqsBg,
    faqsBgT,
    packageBg,
    strategyBg,
  } = usePPCMetadata()
  const modalFunc = React.useRef()

  const sDevices = [
    extractImage(strategy1),
    extractImage(strategy2),
    extractImage(strategy3),
    extractImage(strategy4),
  ]

  const isTablet = useWindowSize().width <= 1023

  return (
    <LayoutInnerPage buttonsHeight={page.buttonsHeight}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        layout="product-intro-custom"
        wrapperClassName={cx.bannerWrapper}
        breadcrumbsClass={cx.breadcrumbs}
        bgImage={null}
        bannerImage={extractImage(bannerImg)}
        title={bannerData.subheading} // top
        titleClassName={bannerData.subheadingClassName}
        breadcrumbs={page.breadcrumbs}
        subtitle2={bannerData.heading} // middle
        subtitle2ClassName={bannerData.headingClassName}
        description={bannerData.desc} // bottom
        descriptionClassName={bannerData.descClassName}
        button_text="Learn More"
        arrowOnButton={false}
        button_click={(e) => modalFunc.current.openModal()}
      />
      <ModalForm
        childFunc={modalFunc}
        desktopTitle="Sign Up Today For A<br/> Free Consultation"
        mobileTitle="Sign Up Today For A<br/> Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m1"
        type="a"
      >
        <FormSignUp
          submitLabel="Get started today"
          messageLabel="Questions or Comments"
        />
      </ModalForm>
      <Tabs
        activeTab={tabsData.activeTab}
        tabsClassName={tabsData.tabsClassName}
      />
      <TabContent
        heading={tabsData.heading}
        headingClassName={tabsData.headingClassName}
        subheading={tabsData.subheading}
        subheadingMobile={tabsData.subheadingMobile}
        subheadingClassName={tabsData.subheadingClassName}
        list={tabsData.list}
        listHeaderClassName={tabsData.listHeaderClassName}
        listDescClassName={tabsData.listDescClassName}
      />
      <WhatWeCanDo
        device={extractImage(wwcdImg)}
        heading={wwcdData.heading}
        headingClassName={wwcdData.headingClassName}
        subheading={wwcdData.subheading}
        subheadingClassName={wwcdData.subheadingClassName}
        subheading2={wwcdData.subheading2}
        subheading2ClassName={wwcdData.subheading2ClassName}
        desc={wwcdData.desc}
        descClassName={wwcdData.descClassName}
        bestFor={wwcdData.bestFor}
        bestForClassName={wwcdData.bestForClassName}
        button_click={(e) => modalFunc.current.openModal()}
      />
      <Bottomline
        device={extractImage(bottomlineBg)}
        heading={bottomlineData.heading}
        headingClassName={bottomlineData.headingClassName}
        subheading={bottomlineData.subheading}
        subheadingClassName={bottomlineData.subheadingClassName}
        desc={bottomlineData.desc}
        descClassName={bottomlineData.descClassName}
        list={bottomlineData.list}
        listClassName={bottomlineData.listClassName}
        button_click={(e) => modalFunc.current.openModal()}
      />
      <Package
        bgImage={extractImage(packageBg)}
        device={extractImage(packageImg)}
        heading={packageData.heading}
        headingClassName={packageData.headingClassName}
        subheading={packageData.subheading}
        subheadingClassName={packageData.subheadingClassName}
        list={packageData.list}
        listClassName={packageData.listClassName}
        button_click={(e) => modalFunc.current.openModal()}
      />
      <Strategy
        bgImage={extractImage(strategyBg)}
        devices={sDevices}
        heading={strategyData.heading}
        headingClassName={strategyData.headingClassName}
        subheading={strategyData.subheading}
        subheadingClassName={strategyData.subheadingClassName}
        list={strategyData.list}
        listHeadingClassName={strategyData.listHeadingClassName}
        listSubheadingClassName={strategyData.listSubheadingClassName}
        listRateClassName={strategyData.listRateClassName}
      />
      <Faqs
        noZoomEffect={isTablet}
        wrapperClassName={cx.faqs}
        bgImg={extractImage(isTablet ? faqsBgT : faqsBg)}
        items={faqsData}
      />
      <ReviewsServiceSetsUsApart
        layout={reviewData.layout}
        title={reviewData.title}
        subtitle={reviewData.subtitle}
        subheader={reviewData.subheader}
        subheaderClassName={reviewData.subheaderClassName}
      />
      <LeadContactForm
        version="LCF02"
        modelOrBackground="GeraldAlt"
        title="Free Consultation"
        subtitle="Get in touch with us for a"
        submitLabel="Let's Talk"
        titleClassName={'heading-3'}
        leadName="Lead Contact Form"
        layout="A"
      />
    </LayoutInnerPage>
  )
}

export default PPC
